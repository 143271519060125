import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState("relative");
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50
        ? setStickyClass("bg-black")
        : setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  // <nav className={`navbar navbar-expand-lg transition-all py-3 ${stickyClass}`}>
  return (
    <header className="header">
				<div className="_container">
					<div className="header__row">

						<div className="icons header__icons">
							<a href="https://twitter.com/meerkat_sol" target="_blank" className="icon-x"><img src="assets/x.svg" alt="" />
              </a>
							<a href="https://t.me/meerkatsolverify" target="_blank" className="icon-tg"><img src="assets/tel.svg" alt="" />
              </a>
							<a href="https://dexscreener.com/solana/6q3pzquyyvh6nu8xwepe1a8zbzvcn4fhsm7tkw8b1gre" target="_blank" className="icon-i"><img src="assets/dex.svg" alt="" />
              </a>
						</div><a href="/" className="header__logo">
							<img src="assets/logo.svg" alt=""/>
						</a>
						<a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5LouMnhwPrgNo5nM6bNhd7FDP2thVC3JBDLUztHfAMzt&outputSymbol=MKAT&fixed=in" className="btn header__btn" target="_blank">BUY $MKAT</a>
					</div>
				</div>
			</header>
  );
};

export default Header;
