import { useEffect, useState } from "react";

import Aos from "aos";
import "aos/dist/aos.css";
// import RouletteComponent from "./RouletteComponent";
import Footer from "../Components/Footer";
import { Wheel } from "react-custom-roulette";


const data = [
	{ option: '5X', style: { backgroundColor: 'white', textColor: '#4BA52F'  } },
  { option: '50X', style: { backgroundColor: 'white', textColor: '#4BA52F'  } },
  { option: '100X',style: { backgroundColor: 'white', textColor: '#4BA52F'  } },
  { option: '150X',style: { backgroundColor: 'white', textColor: '#4BA52F'  } },
  { option: '300X',style: { backgroundColor: 'white', textColor: '#4BA52F'  } },
  ]

const Home = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
const [isOpen, setIsOpen] = useState(false);


const handleSpinClick = () => {
  if (!mustSpin) {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  }
}

const toggleAccordion = () => {
setIsOpen(!isOpen);
};


const [text, setText] = useState(
'5LouMnhwPrgNo5nM6bNhd7FDP2thVC3JBDLUztHfAMzt',
);

const copyToClipboard = () => {
const textarea = document.createElement('textarea');
textarea.value = text;
document.body.appendChild(textarea);

textarea.select();
textarea.setSelectionRange(0, 99999);

document.execCommand('copy');

document.body.removeChild(textarea);

// alert('Token Address Copied: ' + text);
};

useEffect(function () {
Aos.init({ disable: 'mobile' });
}, []);

return (
<>
  <main className="page">
    <div className="position-relative">
      <div className="partners__dec1">
        <img src="assets/bg1.png" className="dec1" alt="" />
        <img src="assets/bg1.png" className="dec2" alt="" />

      </div>
      <section className="hero">
        <div className="_container">
          <div className="hero__row">
            <div className="hero__img1">
              <img src="assets/1.png" alt="" />
            </div>
            <div className="hero__content">
              <div className="hero__capy">MEERKAT MEETS SOLANA CATS!</div>
              <div className="text25 hero__text">They still do not suspect I am a mere cat.</div>
              <a href="https://t.me/meerkatsolverify" className="btn hero__btn sm-btn" target="_blank">join community</a>
            </div>
            <div className="hero__img2">
              <img src="assets/21.png" alt="" />
              <div className="hero__img22">
                <img src="assets/hero/22.png" alt="" />
              </div>
            </div>
          </div>


          <div className="sm-icon">
            <a href="https://t.me/meerkatsolverify" className="btn hero__btn" target="_blank">join community</a>
            <div className="icons header__icons">
              <a href="https://twitter.com/meerkat_sol" target="_blank" className="icon-x"><img src="assets/x.svg" alt="" />
              </a>
              <a href="https://t.me/meerkatsolverify" target="_blank" className="icon-tg"><img src="assets/tel.svg" alt="" />
              </a>
              <a href="https://dexscreener.com/solana/6q3pzquyyvh6nu8xwepe1a8zbzvcn4fhsm7tkw8b1gre" target="_blank" className="icon-i"><img src="assets/dex.svg" alt="" />
              </a>
            </div>
            <div className="center">
              <img src="assets/1.png" className="dec2" height={100} alt="" />
            </div>

          </div>
        </div>
      </section>
      <section className="partners">
        <div className="_container">
          <h3 className="title partners__title">Meerkat featured in</h3>
          <div className="partners__row">

            <div className="partners__items _watcher-view" data-watch="">
              <a href="https://geckoterminal.com/solana/pools/6q3PZqUYyVh6NU8XWEPE1a8zbZvcn4fhSm7tkW8B1GRE" target="_blank">
                <img src="assets/partners/1.svg" alt="" />
              </a>
              <a href="https://dexscreener.com/solana/6q3pzquyyvh6nu8xwepe1a8zbzvcn4fhsm7tkw8b1gre" target="_blank">
                <img src="assets/partners/2.svg" alt="" />
              </a>
              <a href="https://birdeye.so/token/5LouMnhwPrgNo5nM6bNhd7FDP2thVC3JBDLUztHfAMzt?chain=solana" target="_blank">
                <img src="assets/partners/3.svg" alt="" />
              </a>
              <a href="https://dextools.io/app/en/solana/pair-explorer/6q3PZqUYyVh6NU8XWEPE1a8zbZvcn4fhSm7tkW8B1GRE?t=1712581767962" target="_blank">
                <img src="assets/partners/4.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="profit">
        <div className="_container">
          <h3 className="title profit__title">MEERKAT’S PROFIT PREDICTION</h3>
          <div className="profit__row">

            {/* <RouletteComponent /> */}
            <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                fontSize={40}
                fontFamily= "Gluten"
                outerBorderWidth={3}
                innerBorderWidth={5}
                radiusLineWidth={3}
                startingOptionIndex={2}
                disableInitialAnimation={true}
                spinDuration={.2}
                onStopSpinning={() => {
                  setMustSpin(false);
                }}
              />
      <button className="btn roulette__btn" onClick={handleSpinClick}>START</button>
          </div>
          <a href="https://t.me/meerkatsolverify" className="btn profit__btn" target="_blank">join community</a>
        </div>
      </section>
      <section className="about">
        <div className="_container">
          <div className="about__row">
            <div className="about__left">
              <h3 className="title about__title">ABOUT Meerkat </h3>
              <div className="text25 about__text">Solana Cat meta's been all the buzz, but hey, I'm just a meerkat –
                yep, a mere cat over here... Cats and meerkats mingling, what's the deal, Solana? Can we hit pause on
                this silly meta, pretty please?</div>
            </div>
            <div className="about__img _watcher-view" data-watch="">
              <img src="assets/about/1.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="tokenomics">
      <div className="_container">
        <div className="tokenomics__row">
          <div className="tokenomics__img _watcher-view" data-watch="">
            <img src="assets/tokenomics/1.png" alt="" />
          </div>
          <div className="tokenomics__left">
            <h3 className="title tokenomics__title">TOKENOMICS</h3>
            <div className="text25 tokenomics__info">
              <div className="tokenomics__copy">
                <div className="tokenomics__copyblock">
                  <div className="text">
                  5LouMnhwPrgNo5nM6bNhd7FDP2thVC3JBDLUztHfAMzt
                  </div>
                  <div className="tooltip w3-mobile">
                    <button className="text25 copy-btn" onClick={()=>copyToClipboard()}>
                      <span className="tooltiptext">Copy</span>
                    </button>
                  </div>
                </div>
              </div>
              <p>SUPPLY 1.000.000.000</p>
              <p>TAX 0</p>
              <p>BUILT ON SOLANA</p>
            </div>
            <ul className="text25 tokenomics__values">
              <li><span style={{background: "#48A7F8" }}></span> 100% Meerkat</li>
              <li><span style={{background: "#4BA52F" }}></span> 85% LIQUIDITY</li>
              <li><span style={{background: "#BA0001" }}></span> 15% Marketing</li>
            </ul>
          </div>

        </div>
      </div>
    </section>
    <section className="howto">
      <div className="_container">
        <h3 className="title howto__title">HOW TO BE RICH</h3>
        <div className="howto__row">
          <div className="howto__dec1">
            <img src="assets/howto/dec1.png" alt="" />
          </div>

          <div className="howto__dec3">
            <img src="assets/howto/dec1.png" alt="" />
          </div>
          <div className="howto__items">
            <div className="position-relative">
              <div className="text30 howto__item box-1">
                <p>Go get some solana it might be cex like binance or bybit etc</p>
              </div>
              <div className="text30 howto__item box-2">
                <p>Download phantom. The best web3 wallet to stay in touch with $sol world and surely with Meerkat</p>
              </div>
            </div>
            <div className="position-relative ml-auto">
              <div className="howto__img1">
                <img src="assets/howto/1.png" alt="" />
              </div>
              <div className="text30 howto__item box-1">
                <p>Go to raydium or jupiter. Paste Meerkat contract and commit transaction.</p>
              </div>
              <div className="text30 howto__item box-2">
                <p>Now you’re good to go. We are glad to see you among the best people in this world!</p>
              </div>
            </div>
            <div className="text30 howto__item item-none">
              <div className="howto__img2">
                <img src="assets/howto/2.png" alt="" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <div className="position-relative">
      <div className="footer-bg"><img src="assets/footer-bg.png" className="dec1" alt="" />
        <img src="assets/footer-sm.png" className="dec2" alt="" />
      </div>
      <section className="faq">
        <div className="_container">
          <h3 className="title faq__title">FAQ</h3>
          <div className="faq__row">
            <div data-spollers="" className="block _init">
              <div className="block__item">
                <button type="button" data-spoller="" className="block__title _active">Who is Meerkat</button>
                <div className="text25 block__text">Embark on an adventure with the enigmatic meerkat, our mascot,
                  embodying the essence of vigilance and community. Its lockable ears seal off distractions,
                  ensuring focus, while its fine nose sniffs out the most promising opportunities. In the realm
                  of science, meerkats are renowned for their 'sentry' behavior - a perfect metaphor for our
                  community's spirit.</div>
              </div>
              <div className="block__item">
                <button type="button" data-spoller="" className="block__title">WHY $SOL?</button>
                <div className="text25 block__text">Due to the best blockchain of all time we have chosen it as our
                  home.</div>
              </div>
              <div className="block__item">
                <button type="button" data-spoller="" className="block__title">WHAT’S THE FUTURE?</button>
                <div className="text25 block__text">Meerkat is an unpredictable animal, but I can make sure that you
                  will enjoy it so much!</div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  </main>
</>
);
};

export default Home;
