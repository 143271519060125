import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
     <footer className="footer">
				<div className="_container">
					<div className="footer__row">
						<a href="/" className="footer__logo">
							<img src="assets/logo.svg" alt=""/>
						</a>
						<div className="footer__ca"> 5LouMnhwPrgNo5nM6bNhd7FDP2thVC3JBDLUztHfAMzt </div>
						<div className="icons footer__icons">
						<a href="https://twitter.com/meerkat_sol" target="_blank" className="icon-x"><img src="assets/x.svg" alt="" />
              				</a>
							<a href="https://twitter.com/meerkat_sol" target="_blank" className="icon-tg"><img src="assets/tel.svg" alt="" />
              				</a>
							<a href="https://dexscreener.com/solana/6q3pzquyyvh6nu8xwepe1a8zbzvcn4fhsm7tkw8b1gre" target="_blank" className="icon-i"><img src="assets/dex.svg" alt="" />
              				</a>
						</div>
					</div>
					<p>Legal Disclaimer: $MKAT is a meme coin with no intrinsic value or expectation of financial return.
						$MKAT is completely useless and for entertainment purposes only. When you purchase $MKAT, you are
						agreeing that you have seen this disclaimer.</p>
				</div>
			</footer>
    </>
  );
};

export default Footer;
