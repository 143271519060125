import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './App.css';
// import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Pages/Home";
// import RouletteComponent from "./Pages/RouletteComponent";

function App() {
  return (
    <Router>
      <div className="wrapper _loaded">
          <Header />   
          {/* <RouletteComponent />      */}
            <Routes>
              <Route path="/" element={<Home />} />       
            </Routes>
          {/* <Footer />          */}
      </div>
    </Router>
  );
}

export default App;
